


window.loadAlma = async function loadAlma() {
    return Promise.all([
        import('@alma/widgets/dist/widgets.css'),
        (async () => {
            window.Alma = await import("@alma/widgets");
        })(),
    ]);
}
