import * as Sentry from "@sentry/browser";
import { attachErrorHandler } from "@sentry/vue";
import { HttpClient as HttpClientIntegration } from "@sentry/integrations";
import { config } from "@/utils/config";

if(config('app.env') === 'production' && config('sentry.dsn')) {
    Sentry.init({
        dsn: config('sentry.dsn'),
        environment: config('app.env'),
        integrations: [
            new HttpClientIntegration({
                failedRequestStatusCodes: [[400, 599]],
                failedRequestTargets: [
                    config('scout.meilisearch.host'),
                ],
            }),
        ],
        ignoreErrors: [
            // ignore cancelled / offline network errors
            'Network Error',
            'Request failed with status code 419',
            'Could not load "stats"',
            'safari-extension',
            "Unexpected token '<'",
            "timeout of 0ms exceeded",
            "Unexpected end of input",
            'Could not load "util"',
            'The play() request was interrupted',
            'NetworkError when attempting to fetch resource',
            '[ActionID Error]',
            'ResizeObserver loop',
            'AbortError',
            "Can't find variable: gmo",
        ],
        denyUrls: [
            /connect\.facebook\.net/i,
            /maps\.googleapis\.com/i,
            /googletagmanager\.com/i,
        ],
        beforeSend(event, hint) {
            if(event.message as unknown instanceof GeolocationPositionError) {
                return null;
            }
            if (event.exception?.values?.[0]?.stacktrace?.frames?.some((f) => f.filename?.includes(`googletagmanager.com`))) {
                return null;
            }
            return event;
        }
    });

    Sentry.setTag('language', 'javascript');
}

declare global {
    var Sentry: typeof import('@sentry/browser') & { attachErrorHandler: typeof attachErrorHandler };
}

window.Sentry = { ...Sentry, attachErrorHandler };
